<template lang="pug">
  v-autocomplete(
    :label="label"
    :items="listSearchContact"
    item-value="id"
    item-text="content"
    v-model="selectedContact"
    v-on:input="$emit('selected', $event ? $event.id : null)"
    :search-input.sync="searchObj"
    :return-object="true"
    no-data-text="нет результатов"
    outlined
    dense
    hide-details="auto"
    placeholder="введите контакт"
    :disabled="disabled"
    :loading="loading"
    :clearable="!!selectedContact && (selectedContact.id != null)"
    :menu-props="{absolute: false, bottom:true, offsetY:true}"
  )
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'lodash';

export default {
  props: {
    needAll: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchObj: null,
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS_LIST', ['listSearchContact']),

    selectedContact: {
      get() {
        return this.$store.getters['NOTIFICATIONS_LIST/selectedContact'];
      },
      set(value) {
        this.$store.commit('NOTIFICATIONS_LIST/selectedContact', value);
      },
    },
  },

  watch: {
    searchObj(val) {
      let selectedName = this.selectedContact
        ? this.selectedContact.content
        : null;
      val = val ? val.trim() : val;
      selectedName = selectedName ? selectedName.trim() : selectedName;
      val &&
        val.length > 1 &&
        val !== selectedName &&
        this.handleSearchContent(val);
    },
  },

  methods: {
    ...mapActions('NOTIFICATIONS_LIST', {
      querySearchContact: 'querySearchContact',
    }),

    handleSearchContent: debounce.call(
      this,
      async function (value) {
        try {
          await this.querySearchContact(value);
        } catch (e) {
          this.$notify({
            group: 'error',
            type: 'info',
            title: 'Ошибка запроса',
            text: '',
          });
        }
      },
      1000,
    ),
  },
};
</script>
