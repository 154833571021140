<template lang="pug">
ListPageLayout(title="Список получателей")
  template(#filters)
    v-col.d-flex.align-start(cols="12" sm="8" md="6" lg="4")
      v-btn-toggle.mr-3(
        v-model="orgType"
        mandatory
        dense
      )
        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(value="org" v-on="on" color="light-grey")
              v-icon(small) fa-hotel
          span Организация

        v-tooltip(bottom)
          template(v-slot:activator="{ on, attrs }")
            v-btn(value="host" v-on="on" color="light-grey")
              v-icon(small) fa-desktop
          span Хостнейм
      OrganizationPicker(
        v-if="orgType==='org'"
        v-model="selectedOrgId"
        :items="orgList"
        :disabled="$root.isDisabled('recipients')"
        can-select-all
        dense
        outlined
        hide-details="auto"
      )
      v-autocomplete(
        v-if="orgType==='host'"
        label="Хостнейм"
        placeholder="Выберите хостнейм"
        :items="hostList"
        v-model="selectedHostId"
        item-value="id"
        item-text="name"
        :disabled="$root.isDisabled('recipients')"
        dense
        outlined
        hide-details="auto"
      )
    v-col(cols="12" sm="4" md="3")
      MultipleSelect(
        label="Группа"
        placeholder="Выберите группу"
        v-model="selectedGroups"
        :items="groupList"
        item-value="id"
        item-text="name"
        textAllSelected="Все группы"
        :hideAfter="1"
        :disabled="$root.isDisabled('recipients')"
        outlined
        dense
      )
    v-col(cols="12" sm="4" md="3")
      MultipleSelect(
        label="Тип"
        placeholder="Выберите тип"
        v-model="selectedTypes"
        :items="typesNotif"
        item-value="id"
        item-text="reason"
        textAllSelected="Все типы"
        :hideAfter="1"
        :disabled="$root.isDisabled('recipients')"
        outlined
        dense
      )
    v-col(cols="12" sm="4" md="3")
      v-select(
          label="Статус"
          v-model="status"
          :items="statusItems"
          item-value="value"
          item-text="name"
          :disabled="$root.isLoadingGlobal"
          :menu-props="{ offsetY: true }"
          outlined
          dense
          hide-details
        )
    v-col(cols="12" sm="4" md="3")
      autocomplete-contact(
          v-on:selected="setSelectedContact($event)"
          :needAll="false"
          label="Поиск по контакту (email или номер телефона)"
          :disabled="$root.isLoading('recipients')"
        )
    v-col(cols="12" sm="12" md="3")
      v-btn.float-right(
        text
        @click="resetFilters"
        :disabled="$root.isDisabled('recipients')"
      ) Сбросить фильтры

  template(#actions)
    v-btn.mr-6(
      v-if="$can('CREATE_NOTIFY_RECIPIENT')"
      @click="showCreateDialog = true"
      color="primary"
      depressed
    ) Добавление получателя
    v-btn.mr-6(
      v-if="$can('IMPORT_NOTIFY_RECIPIENT')"
      to="table"
      color="primary"
      depressed
    ) Групповое добавление
    v-btn.mr-6(
      v-if="$can('BINDINGS_GET')"
      to="binding"
      color="primary"
      depressed
    ) Привязка к группе

  template(#list)
    v-data-table(
      v-model="selectedItems"
      :headers="headers"
      :items="items"
      item-key="id"
      :loading="$root.isLoadingGlobal && !items.length"
      hide-default-footer
      :items-per-page.sync="limit"
      no-data-text="Нет получателей по данным фильтрам"
      loading-text=""
      show-select
    )
      template(#progress)
        Spinner

      template(#header)
        div.hidden-xs-only
          PerPagePicker(
            v-model="limit"
            data-table
          )

      template(#top)
        v-dialog(v-model="showDeleteDialog" max-width="500")
          v-card.text-center(v-if="$can('DELETE_NOTIFY_RECIPIENT') && showDeleteDialog")
            v-card-title.mb-3
              div.text-center(style="width: 100%") Удалить нотификации?
            v-card-text
              ul(v-if="currentItems")
                li(v-for="recipient in currentItems")
                  p.text-left Для контакта - {{recipient.contact}}
                  p.text-left(v-if="recipient.orgName") Организация - {{recipient.orgName}}
                  p.text-left(v-if="recipient.hostName") Хостнейм - {{recipient.hostName}}
                  p.text-left(v-if="recipient.typeIds") Типы нотификаций - {{recipient.typeNames.active.join(', ')}}
              v-col.d-flex.justify-center.py-0(align-end)
                v-btn.text--secondary.mr-3(@click="toggleDialog('delete')" text) Отмена
                v-btn(@click="submitDelete" depressed color="primary") Удалить

        v-dialog(
          v-model="showEditDialog"
          max-width="800"
          v-if="showEditDialog"
        )
          EditRecipientDialog(
            v-if="showEditDialog"
            :recipient="currentItems[0]"
            :types="typesNotif"
            :loading="$root.isLoadingGlobal"
            @update="fetchList"
            @close="toggleDialog('edit')"
          )
        v-dialog(
          v-model="showCreateDialog"
          max-width="600"
        )
          CreateRecipient(
            v-if="showCreateDialog"
            @update="fetchList"
            @close="showCreateDialog = false"
          )

      template(#item.status="{ item }")
        span(
          style="display: block"
          :class="item.typeIds.active.length ? 'green--text' : 'red--text'"
        )
          | {{item.typeIds.active.length ? 'Активный' : 'Неактивный'}}

      template(#item.types="{ item }")
        div(v-for="typeName in item.typeNames.active")
          | {{typeName}}
        div.text--disabled(v-for="typeName in item.typeNames.notActive")
          | {{typeName}}

      template(#item.org="{ item }")
        v-icon.mr-3(color="brown") {{item.hostName ? "fas fa-desktop" : "fas fa-hotel"}}
        span {{item.hostName ? item.hostName : item.orgName}}

      template(#item.actions="{ item }")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            v-btn(
              v-on="on"
              icon
              v-if="item.typeIds.active.length && ($can('CREATE_NOTIFY_RECIPIENT') || $can('DELETE_NOTIFY_RECIPIENT'))"
              @click="toggleDialog('edit', [item])"
            )
              v-icon(small) fa-pencil-alt
          span Редактировать
        v-tooltip(bottom)
          template(v-slot:activator="{ on }")
            v-btn(
              v-on="on"
              icon
              v-if="item.typeIds.active.length && $can('DELETE_NOTIFY_RECIPIENT')"
              @click="toggleDialog('delete', [item])"
            )
              v-icon.red--text(small) fa-trash-alt

          span Удалить

    v-btn(
      v-show="selectedItems.length > 1"
      fixed
      bottom
      fab
      dark
      large
      color="red"
      @click="toggleDialog('delete', selectedItems)"
    )
      v-icon(dark size="26") fa-trash-alt

  template(#footer)
    v-pagination(
      v-show="isShowPagination"
      v-model="currentPage"
      :length="totalPages"
      total-visible="10"
    )
</template>

<script>
import ListPageLayout from '@/components/layouts/ListPageLayout';
import AutocompleteContact from '@/views/admin-notifications/recipients/AutocompleteContact.vue';
import OrganizationPicker from '@/components/controls/OrganizationPicker.vue';
import MultipleSelect from '@/components/controls/MultipleSelect';
import Spinner from '@/components/Spinner.vue';
import PerPagePicker from '@/components/controls/PerPagePicker';

import { mapActions, mapGetters } from 'vuex';
import { mapGettersMutations } from '@/utils/vuex';
import { STATUS } from '@/utils/constants.js';

export default {
  components: {
    EditRecipientDialog: () =>
      import('@/views/admin-notifications/recipients/EditRecipientDialog.vue'),
    CreateRecipient: () =>
      import('@/views/admin-notifications/recipients/CreateRecipient.vue'),
    ListPageLayout,
    Spinner,
    PerPagePicker,
    AutocompleteContact,
    OrganizationPicker,
    MultipleSelect,
  },

  data() {
    return {
      statusItems: STATUS,

      modalEdit: false,
      currentItems: [],
      selectedItems: [],
      showDeleteDialog: false,
      showEditDialog: false,
      showDeleteButton: false,
      showCreateDialog: false,
      headers: [
        { value: 'status', text: 'Статус', align: 'left', sortable: false },
        { value: 'contact', text: 'Контакт', align: 'left', sortable: false },
        {
          text: 'Типы нотификации',
          value: 'types',
          align: 'center',
          sortable: false,
        },
        {
          text: 'Группа',
          value: 'groupName',
          align: 'center',
          sortable: false,
        },
        {
          value: 'org',
          text: 'Организация/Хостнейм',
          align: 'left',
          sortable: false,
        },
        {
          value: 'actions',
          text: 'Действия',
          align: 'center',
          sortable: false,
        },
      ],
      allTest: [],
    };
  },

  computed: {
    ...mapGetters('NOTIFICATIONS', [
      'groupList',
      'orgList',
      'hostList',
      'typesNotif',
    ]),

    ...mapGetters('NOTIFICATIONS_LIST', [
      'query',
      'items',
      'totalPages',
      'isShowPagination',
      'search',
    ]),

    ...mapGettersMutations('NOTIFICATIONS_LIST', {
      selectedOrgId: 'selectedOrgId',
      selectedHostId: 'selectedHostId',
      selectedTypes: 'selectedTypes',
      selectedGroups: 'selectedGroups',
      currentPage: 'currentPage',
      limit: 'limit',
      status: 'status',
      orgType: 'orgType',
    }),
  },

  mounted() {
    if (!this.orgList.length) {
      this.$store.dispatch('NOTIFICATIONS/queryOrgList');
    }
    if (!this.hostList.length) {
      this.$store.dispatch('NOTIFICATIONS/queryHostList');
    }
    if (!this.groupList.length) {
      this.$store.dispatch('NOTIFICATIONS/getGroups');
    }
    if (!this.typesNotif.length) {
      this.$store.dispatch('NOTIFICATIONS/getTypes');
    }
  },

  methods: {
    ...mapActions('NOTIFICATIONS_LIST', ['fetchList', 'resetFilters']),

    async submitDelete() {
      this.$store.commit('NOTIFICATIONS/currentArray', this.currentItems);
      try {
        await this.$store.dispatch('NOTIFICATIONS/queryDeleteSome');
        this.$notify({
          group: 'note',
          type: 'info',
          title: `Получатель удален`,
          text: '',
        });

        this.toggleDialog('delete');
        this.selectedItems = [];
        this.fetchList();
      } catch (e) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: `Произошла ошибка удаления получателя. Попробуйте позже.`,
          text: '',
        });
      }
    },

    setSelectedContact($event) {
      this.$store.commit('NOTIFICATIONS_LIST/search', $event);
      this.$store.commit('NOTIFICATIONS_LIST/resetCurrentPage');
      this.fetchList();
    },

    toggleDialog(action, items = []) {
      this.currentItems = items;
      action === 'edit'
        ? (this.showEditDialog = !this.showEditDialog)
        : (this.showDeleteDialog = !this.showDeleteDialog);
    },
  },
};
</script>
